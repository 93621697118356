import { Component, OnInit } from '@angular/core';

import { AnnouncementFormService } from './services/announcement-form.service';
import { TranslateService } from './services/translate.service';
import { AnnouncementHttpService } from './services/announcement-http.service';

import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  /* tslint:disable */

  page_title: string;

  constructor(
    private announcement_form: AnnouncementFormService,
    private translate: TranslateService,
    private announcement_http: AnnouncementHttpService,
    private menu: MenuController,
  ) {  }

  ngOnInit() {
    this.translate.pageTitle.subscribe(title => {
      this.page_title = title;
    });
    this.onValueChangeEstateType(1);
  }

  public appPages = [
    {
      title: 'Գլխավոր',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Նոր գույք',
      url: '/announcement',
      icon: 'business'
    },
    {
      title: 'Դասակարգիչներ',
      url: '/classifiers',
      icon: 'list'
    },
    {
      title: 'Բլոգ',
      url: '/posts',
      icon: 'happy'
    },
    {
      title: 'Էջեր',
      url: '/pages',
      icon: 'happy'
    },
    {
      title: 'Օգտատերեր',
      url: '/users',
      icon: 'contacts'
    },
    {
      title: 'Մարզ, համայնք, փողոց',
      url: '/places',
      icon: 'locate'
    },
    {
      title: 'Կարգավորումներ',
      url: '/settings',
      icon: 'settings'
    },
    
  ];

  onValueChangeEstateType(val: number) {
    this.announcement_http.getAnnouncementDetails(val).then(res => {
      this.announcement_http.current_property_type = this.announcement_form.post_announcement_form['property_type_id'] = this.announcement_http.property_types.find(property_type => {
        return property_type.parent_id === +val;
      }).id;
      this.announcement_form.announcementType.next(this.announcement_http.current_property_type);
    });
    this.announcement_http.edit = false;
    this.announcement_http.image_urls = [];
    this.announcement_http.old_image_urls = [];
    this.announcement_form.resetAdditionalForm.next(true);
    this.announcement_form.resetProfessionalForm.next(true);
    this.announcement_form.resetForms.next(false);
  }

  onLangChange(event: Event) {
    this.translate.lang.next(event.target['value'] as 'hy' | 'ru' | 'en');
  }

  openSideMenu() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  onMenuItemChanged(title: string) {
    this.translate.pageTitle.next(title);
    if (title == 'Նոր գույք') {
      this.announcement_http.edit = false;
      this.announcement_form.currentAnnouncement = null;
      // this.announcement_form.resetForms.next(0);
      // if (this.announcement_form.announcementForm_base) {
      //   this.announcement_form.announcementForm_base.reset();
      // }
      if (this.announcement_form.announcementFormMain) {
        this.announcement_form.announcementFormMain.reset();
      }
      // if (this.announcement_form.announcementFormTypical) {
      //   this.announcement_form.announcementFormTypical.reset();
      // }
      // if (this.announcement_form.announcementFormProfessional) {
      //   this.announcement_form.announcementFormProfessional.reset();
      // }
      if (this.announcement_form.announcementFormAdditional) {
        this.announcement_form.announcementFormAdditional.reset();
      }
      // if (this.announcement_form.seoSettingsForm) {
      //   this.announcement_form.seoSettingsForm.reset();
      // }
    }
  }

}
