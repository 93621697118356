import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';


const routes: Routes = [

  { path: '', redirectTo: 'log-in', pathMatch: 'full' },
  { path: 'announcement', loadChildren: './estate/estate.module#EstatePageModule', canActivate: [AuthGuard] },
  { path: 'home', loadChildren: './home/home.module#HomePageModule', canActivate: [AuthGuard] },
  { path: 'classifiers', loadChildren: './classifiers/classifiers.module#ClassifiersPageModule', canActivate: [AuthGuard] },
  { path: 'users', loadChildren: './users/users.module#UsersPageModule', canActivate: [AuthGuard] },
  { path: 'posts', loadChildren: './posts/posts.module#PostsPageModule', canActivate: [AuthGuard] },
  { path: 'pages', loadChildren: './pages/pages.module#PagesPageModule', canActivate: [AuthGuard] },
  { path: 'places', loadChildren: './places/places.module#PlacesPageModule', canActivate: [AuthGuard] },
  { path: 'settings', loadChildren: './settings/settings.module#SettingsPageModule', canActivate: [AuthGuard] },
  { path: 'log-in', loadChildren: './log-in/log-in.module#LogInPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
