import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { AnnouncementOption } from '../interfaces/new announcement/announcement_option';
import { AnnouncementHttpService } from './announcement-http.service';
import { AnnouncementOptionType } from '../interfaces/new announcement/announcement_option_type';
import { AnnouncementOtherBuilding } from '../interfaces/new announcement/announcement_other_building';
import { SeoSetting } from '../interfaces/new announcement/seo_settings';
import { User } from '../interfaces/users/users';
import { Place } from '../interfaces/new announcement/places';
import { AnnouncementType } from '../interfaces/new announcement/announcement_types';
import { Currency } from '../interfaces/new announcement/currency';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementFormService {
  /* tslint:disable */
  resetForms = new Subject();
  resetTopForm = new Subject();
  resetAdditionalForm = new BehaviorSubject<boolean>(false);
  resetProfessionalForm = new BehaviorSubject<boolean>(false);
  saveDisabled = new BehaviorSubject(true);

  post_announcement_form: object = {};
  post_announcement_form_images: FormData;
  post_announcement_form_top: object = {};
  post_announcement_form_main: any = {};
  post_announcement_other_buildings: Array<AnnouncementOtherBuilding> = [];
  post_announcement_form_typical: object = {};
  post_announcement_form_professional: FormGroup;
  post_announcement_form_additional: object = {};
  post_seo_settings: Array<SeoSetting> = [];

  announcementType = new BehaviorSubject<number>(1);
  announcementForm_base: FormGroup;
  announcementFormTypical: FormGroup;
  announcementFormMain: FormGroup;
  announcementFormProfessional: FormGroup;
  announcementFormAdditional: FormGroup;
  announcementOtherBuildings: Array<any> = [];
  seoSettingsForm: FormGroup;

  agents: User[] = [];
  agent: User;
  agentControl: FormControl;

  sellers: User[] = [];
  seller: User;
  sellerControl: FormControl;

  states: Place[] = [];
  state: Place;
  stateControl: FormControl;

  cities: Place[] = [];
  city: Place;
  cityControl: FormControl;

  streets: Place[] = [];
  street: Place;
  streetControl: FormControl;

  typeOfContracts: Array<AnnouncementType> = [];
  currentAnnouncement: any;

  is_land = false;

  constructor(
    private fb: FormBuilder,
    private announcement_http: AnnouncementHttpService
  ) {
    this.announcementType.next(1);
  }

  announcementTypicalFormInfo: object = {
    // Apratment
    1: {
      rows: [
        // Row-1
        [
          {
            type: 'input',
            _type: 'text',
            label: 'building',
            label_position: 'floating',
            formControlName: 'building'
          },
          {
            type: 'input',
            _type: 'number',
            label: 'apartment',
            label_position: 'floating',
            formControlName: 'apartment'
          }
        ],
        // Row-2
        [
          {
            type: 'input',
            _type: 'number',
            label: 'floor',
            label_position: 'floating',
            formControlName: 'floor'
          },
          {
            type: 'input',
            _type: 'number',
            label: 'building_floor',
            label_position: 'floating',
            formControlName: 'building_floor'
          },
          {
            type: 'rooms',
            _type: 'number',
            label: 'rooms',
            label_position: 'floating'
          }
        ],
        // Row-3
        [
          {
            type: 'input',
            _type: 'number',
            label: 'total_area',
            formControlName: 'area',
            label_position: 'floating'
          },
          {
            type: 'input',
            _type: 'number',
            label: 'price',
            formControlName: 'price',
            label_position: 'floating'
          },
          {
            type: 'select',
            formControlName: 'price_currency_id',
            options: ['USD', 'RUB', 'AMD', 'EUR']
          }
        ]
      ]
    },
    // Detached house
    2: {
      rows: [
        // Row 1
        [
          {
            type: 'input',
            _type: 'text',
            label: 'building',
            formControlName: 'building',
            label_position: 'floating'
          },
          {
            type: 'input',
            _type: 'text',
            label: 'certificate',
            formControlName: 'certificate',
            label_position: 'floating'
          }
        ],
        // Row 2
        [
          {
            type: 'rooms',
            _type: 'number',
            label: 'rooms',
            formControlName: 'rooms1',
            label_position: 'floating'
          },
          {
            type: 'input',
            _type: 'number',
            label: 'land_area',
            formControlName: 'land_area',
            label_position: 'floating'
          },
          {
            type: 'input',
            _type: 'number',
            label: 'area',
            formControlName: 'area',
            label_position: 'floating'
          }
        ],
        // Row 3
        [
          {
            type: 'input',
            _type: 'number',
            label: 'price',
            formControlName: 'price',
            label_position: 'floating'
          },
          {
            type: 'select',
            formControlName: 'price_currency_id',
            options: ['USD', 'RUR', 'AMD', 'EUR']
          }
        ]
      ]
    },
    // Commercial
    3: {
      rows: [
        // Row 1
        [
          {
            type: 'input',
            _type: 'text',
            label: 'building',
            formControlName: 'building',
            label_position: 'floating'
          },
          /* {
            type: 'checkbox',
            label: 'separate_building',
            formControlName: 'separate_building'
          }, */
          /* {
            type: 'input',
            _type: 'number',
            label: 'for_area',
            formControlName: 'area_number',
            label_position: 'floating'
          } */
        ],
        // Row 2
        [
          {
            type: 'input',
            _type: 'number',
            label: 'floor',
            label_position: 'floating',
            formControlName: 'floor'
          },
          {
            type: 'input',
            _type: 'number',
            label: 'building_floor',
            label_position: 'floating',
            formControlName: 'building_floor'
          },
        ],
        // Row 3
        [
          {
            type: 'rooms',
            _type: 'number',
            label: 'halls',
            label_position: 'floating'
          },
          {
            type: 'input',
            _type: 'number',
            label: 'area',
            formControlName: 'area',
            label_position: 'floating'
          },
          {
            type: 'input',
            _type: 'number',
            label: 'price',
            formControlName: 'price',
            label_position: 'floating'
          },
          {
            type: 'select',
            formControlName: 'price_currency_id',
            options: ['USD', 'RUR', 'AMD', 'EUR']
          }
        ]
      ]
    },
    // Land
    4: {
      rows: [
        // Row 1
        [
          {
            type: 'input',
            _type: 'number',
            label: 'total_area',
            formControlName: 'land_area',
            label_position: 'floating'
          },
          {
            type: 'input',
            _type: 'text',
            label: 'certificate',
            formControlName: 'certificate',
            label_position: 'floating'
          }
        ],
        // Row 2
        [
          // {
          //   type: 'input',
          //   _type: 'text',
          //   label: 'land',
          //   formControlName: 'land',
          //   label_position: 'floating'
          // },
          {
            type: 'input',
            _type: 'number',
            label: 'price',
            formControlName: 'price',
            label_position: 'floating'
          },
          {
            type: 'select',
            formControlName: 'price_currency_id',
            options: ['USD', 'RUR', 'AMD', 'EUR']
          }
        ]
      ]
    }
  };

  createForm(editable: boolean): Promise<FormGroup> {
    return new Promise<FormGroup>(resolve => {
      let form: FormGroup;
      if (!editable) {
        form = this.fb.group({
          typeOfContract: ['', [Validators.required]],
          agent: ['', [Validators.required]],
          salesman: [''],
          state: ['', [Validators.required]],
          city: [{ value: '', disabled: true }, [Validators.required]],
          street: [{ value: '', disabled: true }, [Validators.required]]
        });
        resolve(form);
      } else {
        this.announcement_http.getAnnouncementDetails().then(res => {
          this.states = this.announcement_http.getPlaceOptions(1, 'hy');
          this.typeOfContracts = this.announcement_http.announcement_types;
          this.agents = this.announcement_http.getAgents();
          this.sellers = this.announcement_http.getSellers();

          this.announcement_http.currentAnnouncement.subscribe(res => {
            this.cities = this.announcement_http.get({
              array: 'places',
              with: { place_type_id: 2, parent_id: res['state'].id }
            });
            this.streets = this.announcement_http.get({
              array: 'places',
              with: { place_type_id: 3, parent_id: res['city'].id }
            });
            this.currentAnnouncement = res;

            if (this.currentAnnouncement['agent']) {
              this.agentControl = this.fb.control(
                this.agents.find(agent => {
                  return (
                    agent.id ===
                    this.announcement_http.get({
                      array: 'users',
                      with: { name: this.currentAnnouncement['agent'] }
                    })[0]['id']
                  );
                })
              );              
            }

            this.sellerControl = this.fb.control(
              this.sellers.find(seller => {
                if(this.currentAnnouncement['seller']) {
                  return (
                    seller.id ===
                    this.announcement_http.get({
                      array: 'users',
                      with: { name: this.currentAnnouncement['seller'] }
                    })[0]['id']
                  );
                }
              })
            );

            this.stateControl = this.fb.control(
              this.announcement_http.places.find(state => {
                return (
                  state.name ===
                  this.announcement_http.get({
                    array: 'places',
                    with: { id: this.currentAnnouncement['state'].id }
                  })[0]['name']
                );
              })
            );

            this.cityControl = this.fb.control(
              this.announcement_http.places.find(city => {
                return (
                  city.name ===
                  this.announcement_http.get({
                    array: 'places',
                    with: { id: this.currentAnnouncement['city'].id }
                  })[0]['name']
                );
              })
            );

            this.streetControl = this.fb.control(
              this.announcement_http.places.find(street => {
                return (
                  street.name ===
                  this.announcement_http.get({
                    array: 'places',
                    with: { id: this.currentAnnouncement['street'].id }
                  })[0]['name']
                );
              })
            );

            if (this.currentAnnouncement['agent']) {
              this.post_announcement_form_top['agent_id'] = this.agentControl.value.id;
            }
            this.post_announcement_form_top['seller_id'] = this.sellerControl.value ? this.sellerControl.value.id : '';
            this.post_announcement_form_top['street'] = this.streetControl.value.name;
            this.post_announcement_form_top['place_id'] = this.streetControl.value.id;
            this.post_announcement_form_top['announcement_type_id'] = this.announcement_http.get<AnnouncementType>({array: 'announcement_types', with: {title: this.currentAnnouncement['typeOfContract']}})[0].id;

            form = this.fb.group({
              typeOfContract: this.currentAnnouncement['typeOfContract'],
              agent: this.agentControl,
              salesman: this.sellerControl,
              state: this.stateControl,
              city: this.cityControl,
              street: this.streetControl,
              apartment: this.currentAnnouncement['apartment']
            });
            resolve(form);
          });
        });
      }
    });
  }

  createFormArray(parent_id, editable: boolean): Promise<FormGroup> {
    return new Promise<FormGroup>(resolve => {
      if (!editable) {
        switch (+parent_id) {
          case 1:
            this.announcementFormTypical = this.fb.group({
              building: [''],
              apartment: [''],
              floor: [''],
              building_floor: [''],
              rooms: [''],
              // rooms2: [''],
              area: [''],
              price: [''],
              price_currency_id: [2],
              // service_fee: [''],
              service_fee_currency_id: [1]
            });
            break;
          case 2:
            this.announcementFormTypical = this.fb.group({
              building: [''],
              certificate: [''],
              rooms: [''],
              // rooms2: [''],
              land_area: [''],
              area: [''],
              price: [''],
              price_currency_id: [2],
              // service_fee: [''],
              service_fee_currency_id: [1]
            });
            break;
          case 3:
            this.announcementFormTypical = this.fb.group({
              building: [''],
              floor: [''],
              building_floor: [''],
              // area_number: [''],
              rooms: [''],
              // rooms2: [''],
              area: [''],
              price: [''],
              price_currency_id: [2],
              // service_fee: [''],
              service_fee_currency_id: [1]
            });
            break;
          case 4:
            this.announcementFormTypical = this.fb.group({
              // land: [''],
              certificate: [''],
              land_area: [''],
              price: [''],
              price_currency_id: [2],
              // service_fee: [''],
              service_fee_currency_id: [1]
            });
        }
        resolve(this.announcementFormTypical);
      } else {
        this.announcement_http.currentAnnouncement.subscribe(res => {
          let rooms1, rooms2;
          if (res['rooms']) {
            rooms1 = res['rooms'].split('-')[0] ? res['rooms'].split('-')[0] : '';
            rooms2 = res['rooms'].split('-')[1] ? res['rooms'].split('-')[1] : '';
          }
          this.announcementFormTypical = this.fb.group({
            apartment: res['apartment'] ? res['apartment'] : '',
            building: res['building'] ? res['building'] : '',
            certificate: res['certificate'] ? res['certificate'] : '',
            check_agent_id: res['check_agent_id'] ? res['check_agent_id'] : '',
            building_floor: res['building_floor'] ? res['building_floor'] : '',
            separate_building: res['separate_building'] ? res['separate_building'] : '',
            // area_number: res['area_number'] ? res['area_number'] : '',
            floor: res['floor'] ? res['floor'] : '',
            land_area: res['land_area'] ? res['land_area'] : '',
            price: res['price'] ? res['price'] : '',
            price_currency_id: res['price_currency_id'] ? res['price_currency_id'] : '',
            rooms: res['rooms'] ? res['rooms'] : '',
            // rooms1: (rooms1 && rooms1 !== null) ? rooms1 : '',
            // rooms2: (rooms2 && rooms2 !== null) ? rooms2 : '',
            area: res['area'] ? res['area'] : '',
            // service_fee: res['service_fee'] ? res['service_fee'] : '',
            service_fee_currency_id: res['service_fee_currency_id'] ? res['service_fee_currency_id'] : ''
          });
          resolve(this.announcementFormTypical);
        });
      }
    });
  }

  toFormGroupMain(
    option_types: Array<AnnouncementOptionType>,
    options: Array<AnnouncementOption>,
    currentAnnouncement?: any,
    property_id?: number,
    service_fee?: number,
    service_fee_currency_id?: number,
    front_length?: number
  ): FormGroup {
    if (+property_id !== 4) {
      const group: any = {};

      option_types.forEach(option_type => {
        if (option_type.multiselect === 0 || option_type.multiselect === 2) {
          group[option_type.id] = new FormControl('');
        }
      });
      options.forEach(option => {
        group[option.id] = new FormControl(false);
      });
      
      group['service_fee'] = new FormControl('');
      group['service_fee_currency_id'] = new FormControl(1);
      group['front_length'] = new FormControl('');

      if (currentAnnouncement) {
        currentAnnouncement.additional_options.forEach(option => {
          group[option.type_id] = new FormControl(option.title);
        });

        currentAnnouncement.amenities.forEach(checkbox => {
          group[checkbox.pivot.option_id] = new FormControl(true);
        });

        group['service_fee'] = new FormControl(currentAnnouncement.service_fee);
        group['service_fee_currency_id'] = new FormControl(currentAnnouncement.service_fee_currency_id);
        group['front_length'] = new FormControl(front_length);
      }

      const form = new FormGroup(group);
      /* this.post_announcement_form_main['options'] = [];
      Object.entries(form.value).forEach(key_value => {
        if (key_value[1]) {
          this.post_announcement_form_main['options'].push(key_value[0]);
        }
      }); */
      // this.post_announcement_form_main = form.value;
      this.is_land = false;
      return form;
    } else {
      const group = {};

      option_types.forEach(option_type => {
        group[option_type.id] = new FormControl('', [Validators.required]);
      });

      group['front_length'] = new FormControl('');

      if (currentAnnouncement) {
        currentAnnouncement.additional_options.forEach(option => {
          group[option.type_id] = new FormControl(option.title);
        });
        group['front_length'] = new FormControl(currentAnnouncement.front_length);
      }

      const form = new FormGroup(group);

      this.is_land = true;
      return form;
    }

  }

  toFormGroupProf(arr: Array<AnnouncementOptionType>): FormGroup {
    const group: any = {};

    arr.forEach(option_type => {
      group[option_type.id] = new FormControl('', [Validators.required]);
    });

    const form = new FormGroup(group);
    this.announcementFormProfessional = form;

    return form;
  }

  buildAnnouncementForm(): object {
    this.announcementType.subscribe(property_type_id => {
      this.post_announcement_form['property_type_id'] = property_type_id;
    });
    Object.entries(this.post_announcement_form_top).forEach(key_value => {
      if (key_value[0] !== 'place_id') {
        this.post_announcement_form[key_value[0]] = key_value[1];
      }
    });
    Object.entries(this.post_announcement_form_main).forEach(key_value => {
      this.post_announcement_form[key_value[0]] = key_value[1];
    });
    this.post_announcement_form['service_fee'] = this.post_announcement_form_main['service_fee'];
    this.post_announcement_form['front_length'] = this.post_announcement_form_main['front_length'];
    this.post_announcement_form['other_buildings'] = this.post_announcement_other_buildings;
    Object.entries(this.post_announcement_form_typical).forEach(key_value => {
      this.post_announcement_form[key_value[0]] = key_value[1];
    });
    if (this.post_announcement_form_professional) {
      Object.entries(this.post_announcement_form_professional.value).forEach(
        key_value => {
          this.post_announcement_form[key_value[0]] = key_value[1];
        }
      );
    }
    Object.entries(this.post_announcement_form_additional).forEach(key_value => {
        this.post_announcement_form[key_value[0]] = key_value[1];
      }
    );
    this.post_announcement_form['seo'] = Object.values(this.post_seo_settings);
    return this.post_announcement_form;
  }
}
