import { Injectable } from '@angular/core';

import { AnnouncementOptionType } from '../interfaces/new announcement/announcement_option_type';
import { AnnouncementOption } from '../interfaces/new announcement/announcement_option';
import { PropertyType } from '../interfaces/new announcement/property_type';
import { AnnouncementType } from '../interfaces/new announcement/announcement_types';
import { Currency } from '../interfaces/new announcement/currency';
import { PlaceType } from '../interfaces/new announcement/place_types';
import { Place } from '../interfaces/new announcement/places';
import { User } from '../interfaces/users/users';
import { UserType } from '../interfaces/users/user_types';
import { OtherBuilding } from '../interfaces/new announcement/other_building';
import { AnnouncementStatusType } from '../interfaces/new announcement/announcement_status_type';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

const API_URL = environment.API_URL;

const httpHeaders = new HttpHeaders({
  'Access-Control-Allow-Origin': '*',
  'Content-type': 'application/json',
  'Access-Control-Allow-Methods': '*',
  'Access-Control-Allow-Credentials': 'true'
});

@Injectable({
  providedIn: 'root'
})
export class AnnouncementHttpService {
  /* tslint:disable */

  dataReady: Promise<any>;
  currentAnnouncement = new BehaviorSubject<object>(null);
  mainImageIndex: number;
  edit: boolean = false;
  announcementId: number;
  image_urls: Array<{path: string, selected?: boolean, visible?: boolean, alt?: string, title: string}> = [];
  old_image_urls: Array<{path: string, selected?: boolean, visible?: boolean, alt?: string, title: string}> = [];

  // resetAfterAnnStore = new BehaviorSubject<any>(false);

  constructor(private http: HttpClient, private router: Router) {
  }

  getAnnouncementDetails(property_type?) {
    property_type = property_type ? property_type : this.current_property_type;
    return new Promise(_res => {
      this.http.get(`${API_URL}/hy/announcement/create/` + property_type).subscribe(res => {
        this.announcement_options = res['announcement_options'];
        this.announcement_option_types = res['announcement_option_types'];
        this.announcement_status_types = res['announcement_status_types'];
        this.announcement_types = res['announcement_types'];
        this.currencies = res['currencies'];
        this.other_buildings = res['other_buildings'];
        this.place_types = res['place_types'];
        this.places = res['places'];
        this.property_types = res['property_types'];
        this.user_types = res['user_types'];
        this.users = res['users'];
        this.other_buildings = res['other_buildings'];
        this.source_id = res['source_id'];
        this.company_id = res['company_id'];
        _res();
      });
    });
  }

  postAnnouncementData(obj: object, formData?: FormData, urls: Array<{path: string, title: string}> = [], pdfFormData?: FormData) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    if (!this.edit) {
      this.http.post(`${API_URL}/hy/announcement/store`, {announcement: obj}).subscribe(res=> {
        this.announcementId = res['announcement_id'];
        this.http.post(`${API_URL}/hy/files/${res['announcement_id']}/${this.mainImageIndex}`, formData, {headers: headers}).subscribe(res => {
          formData = null;
        },
        err => {
        });
        /* this.http.post(`${API_URL}/hy/files/${res['announcement_id']}/${null}`, pdfFormData, {headers: headers}).subscribe(res => {
        },
        err => {
        }); */
        this.router.navigate([`announcement/` + this.announcementId + `/edit`]);
      });
    } else {
      this.http.put(`${API_URL}/hy/announcement/${this.announcementId}/update`, {announcement: obj}).subscribe(res=> {
        this.http.post(`${API_URL}/hy/update_img/${res['announcement_id']}/${this.mainImageIndex}`, {image_data: urls}, {headers: headers}).subscribe(res => {
          this.old_image_urls = [...this.image_urls];
          this.image_urls = [];
        },
        err => {
        });
        this.http.post(`${API_URL}/hy/files/${res['announcement_id']}/${null}`, pdfFormData, {headers: headers}).subscribe(res => {
          console.log(res)
        },
        err => {
        });
      });
    }
  }

  postAnn

  deleteAnnPdf(id: number, ind: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.delete(`${API_URL}/hy/files/pdf/delete/${id}/${ind}`, {headers: headers});
  }

  getAnnouncementById(id: number): Observable<object> {
    return this.http.get<Observable<object>>(`${API_URL}/hy/announcement/${id}`);
  }

  getAllOtherBuildings(): Observable<Array<OtherBuilding>> {
    return this.http.get<Array<OtherBuilding>>(`${API_URL}/hy/other_buildings/index`);
  }

  source_id: Array<any>;

  //  0 - select, 1 - checkbox, 2 - text, 3 - textarea

  announcement_option_types: Array<AnnouncementOptionType> = [];

  announcement_options: Array<AnnouncementOption> = [];

  property_types: Array<PropertyType> = [];

  current_property_type: number;

  announcement_status_types: Array<AnnouncementStatusType> = [];

  announcement_types: Array<AnnouncementType> = [];

  currencies: Array<Currency> = [];

  place_types: Array<PlaceType> = [];

  places: Array<Place> = [];

  user_types: Array<UserType> = [];

  users: Array<User> = [];

  company_id: number;

  other_buildings: Array<OtherBuilding> = [];

  rooms: Array<object> = [
    {
      id: 1,
      name: '1'
    },
    {
      id: 2,
      name: '2'
    },
    {
      id: 3,
      name: '3'
    },
    {
      id: 4,
      name: '4'
    },
    {
      id: 5,
      name: '4+'
    },
  ];

  type_of_commercial_use: Array<object> = [
    {
      id: 1,
      name: 'գրասենյակային'
    },
    {
      id: 2,
      name: 'առևտրի սրահներ'
    },
    {
      id: 3,
      name: 'կոմերցիոն համալիրներ'
    },
    {
      id: 4,
      name: 'պահեստային տարածքներ, արտադրամասեր'
    },
    {
      id: 1,
      name: 'հանրային սննդի օբյեկտներ'
    },
    {
      id: 1,
      name: 'ունիվերսալ'
    },
    {
      id: 1,
      name: 'ավտոլվացման կետ'
    },
  ];

  land_use: Array<object> = [
    {
      id: 1,
      name: 'Գյուղատնտեսական'
    },
    {
      id: 2,
      name: 'Բնակավայրերի'
    },
    {
      id: 3,
      name: 'Արդյունաբերական'
    },
    {
      id: 4,
      name: 'Այգի'
    },
    {
      id: 5,
      name: 'Չունի հող /կոմերցիոն/'
    },
  ];

  getSellers(): Array<User> {
    return this.users.filter(user => {
      return user.user_type_id === 2 || user.user_type_id === 4 || user.user_type_id === 5 || user.user_type_id === 7;
    });
  }

  getAgents(): Array<User> {
    return this.users.filter(user => {
      return user.user_type_id === 1 || user.user_type_id === 4;
    });
  }

  getRenters(): Array<User> {
    return this.users.filter(user => {
      return user.user_type_id === 8;
    });
  }

  getPlaceOptions(parentId: number, lang: string): Array<Place> {
    if (lang && parentId) {
      return this.places.filter(place => {
        return place.locale === lang && place.parent_locale_id === parentId;
      });
    }
  }

  getOptionType(id: number): AnnouncementOptionType {
    return this.announcement_option_types.find(type => {
      return type.parent_id === id;
    });
  }

  getOptions(typeId: number): Array<AnnouncementOption> {
    return this.announcement_options.filter(option => {
      return option.type_id === typeId;
    });
  }

  getTypesWithMultiselect(id1: number, id2?: number, id3?: number): Array<AnnouncementOptionType> {
    return this.announcement_option_types.filter(type => {
      return type.multiselect === id1 || type.multiselect === id2 || type.multiselect === id3;
    });
  }

  getOptionIdWithValue(value: string, typeId: number) {
    const x = this.announcement_options.find(option => {
      return option.title === value && option.type_id === typeId;
    });

    if (x) {
      return x;
    }
  }

  getOtherBuildingId(option: string): number {
    const x = this.other_buildings.find(_option => {
      return _option.title === option;
    });
    if (x) {
      return x.id;
    }
  }

  getId(arr: Array<object>, name: string): any {
    return arr.find(elem => {
      return elem['title'] === name || elem['name'] === name || elem['value'] === name || elem['code'] === name;
    });
  }

  get<T>(destructive: {array: string, with: {}, without?: {}}):T[] {
    const {array: name, with: with_params, without: without_params} = destructive;
    let filtered = [];
    if (this[name]) {
      if (Object.entries(with_params).length === 0 && with_params.constructor === Object) {
        filtered = this[name];
      } else {
        this[name].forEach((elem, ind) => {
          Object.entries(with_params).forEach(key_value => {
            if (elem[key_value[0]] === key_value[1]) {
              filtered.push(elem);
            }
          });
        });
      }
      if (without_params) {
        Object.entries(without_params).forEach(key_value => {
          filtered = filtered.filter(elem => {
            return elem[key_value[0]] !== key_value[1];
          })
        });
      }
    }
    return filtered;
  }

  getPlaces(parent_id: number, place_type_id: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.get<Array<Place>>(`${API_URL}/hy/place/places/${place_type_id}/${parent_id}`);
  }

  postArchiveRentDate(obj: object) {
    return this.http.post(`${API_URL}/hy/announcement/status`, obj);
  }

  postChangeImagesOrder(obj: object) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    
    return this.http.post(`${API_URL}/hy/files/change_images_order`, obj);
  }

}
