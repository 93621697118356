import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  /* tslint:disable */
  pageTitle = new BehaviorSubject<string>('Create Announcement');
  lang = new BehaviorSubject<'ru' | 'hy' | 'en'>('hy');

  traslateData = {
    // Armenian
    hy: {
      new_announcement: {
        global: {
          save: 'Պահպանել',
          save_and_close: 'Պահպանել և փակել',
          cancel: 'Չեղյալ',
          add_image: 'Ավելացնել նկարներ',
          delete_image: 'Ջնջել ընտրված նկարները',
          select_all: 'Ընտրել բոլոր նկարները',
          main: 'Հիմնական',
          professional: 'Մասնագիտական',
          additional: 'Լրացուցիչ'
        },
        main: {
          building_apartment: 'Շենք/Բնակարան',
          utilities: 'Կոմունալ հարմարություններ',
          map: 'Քարտեզ',
          land: 'Հող'
        },
        professional: {
          notes: 'Նոթեր',
          prof_note: 'Մասնագիտական կարծիք,Վերլուծություն',
          why_note: 'Ինչու ես ձեռք չէի բերի այս գույքը',
          other_note: 'Այլ նոթեր',
          description: 'Նկարագրություն',
          other_buildings: {
            title: 'Այլ Կառույցներ',
            type: 'Տեսակ',
            area: 'Մակերես',
            description: 'Մեկնաբանություն',
            add_button: 'Ավելացնել'
          }
        },
        additional: {
          title: 'Մասնագետներ',
          seo_header: 'Վերնագիր SEO',
          seo_description: 'Նկարագրություն SEO'
        },
        typeOfContract: 'Կոնտրակտի տեսակ',
        agent: 'Գործակալ',
        // agent_options: this.announcement.getAgents('hy'),
        salesman: 'Վաճառող',
        state: 'Մարզ',
        city: 'Քաղաք',
        street: 'Փողոց',
        1: {
          building: 'Շենք',
          apartment: 'Բնակարան',
          floor: 'Հարկ',
          building_floor: 'Շենքի հարկ',
          rooms: 'Սենյակներ',
          ceiling_height: 'Առաստաղի բարձրություն',
          total_area: 'Ընդհանուր մակերես',
          price: 'Գին',
        },
        2: {
          building: 'Շենք',
          certificate: 'Վկայական',
          rooms: 'Սենյակներ',
          land_area: 'Հողամասի մակերես',
          area: 'Շինության մակերես',
          price: 'Գին',
        },
        3: {
          building: 'Շենք',
          separate_building: 'Առանձին շինություն',
          floor: 'Հարկ',
          building_floor: 'Շենքի հարկ',
          for_area: 'Տարածքի համար',
          halls: 'Սրահներ',
          area: 'Ընդհանուր մակերես',
          price: 'Գին',
        },
        4: {
          land: 'Հող',
          certificate: 'Վկայական',
          total_area: 'Ընդհանուր մակերես',
          price: 'Գին',
        }
      },
      places: {
        all_places: 'Բոլոր բնակավայրերը',
        new: 'Նոր'
      },
      classifiers: {
        property_type: 'Գույքի տեսակ',
        other_buildings_title: 'Այլ կառույցներ',
        new: 'Նոր',
        all_classifiers: 'Բոլոր դասակարգիչները',
        name: 'Անուն',
        add: 'Ավելացնել',
        select_one: 'Ընտրեք առնվազն մեկը',
        edit: 'Խմբագրել',
        close: 'Փակել',
        save: 'Պահպանել',
        delete: 'Ջնջել',
        add_close: 'Ավելացնել և փակել'
      },
      users: {
        clear: 'Մաքրել',
        search: 'Փնտրել',
        new: 'Նոր',
        send_mail: 'Ուղարկել նամակ',
        download_exe: 'Ներբեռնել exe',
        results: 'Ընդհանուր քանակ',
        code: 'Կոդ',
        user_type: 'Կոնտակտի տեսակը',
        name: 'Անուն',
        phone: 'Հեռ.',
        viber: 'Viber',
        whatsapp: 'WhatsApp',
        contact_code: 'Կոնտակտի տեսակ',
        mail: 'Էլ. հասցե',
        urgent: 'Շտապ',
        createdAt: 'Ստեղծված',
        updatedAt: 'Թարմացված',
        to: 'Ից',
        new_user: {
          upload_photo: 'Ներբեռնել նկար',
          name: 'Անուն',
          surname: 'Ազգանուն',
          mail: 'Էլ. հասցե',
          tel: 'Բջջ. հեռ.',          
          password: 'Գաղտնաբառ',
          password_confirm: 'Հաստատել գաղտնաբառը',
          office_tel: 'Գրասենյակի հեռ.',
          home_tel: 'Տան հեռ.',
          viber: 'Viber',
          whatsapp: 'WhatsApp',
          skype: 'Skype',
          description: 'Մեկնաբանություն'
        },
        mail_component: {
          users_email_address: 'Հասցեատիրոջ էլ. հասցեն',
          subject: 'Թեմա',
          additional_documents: 'Լրացուցիչ փաստաթղթեր',
          add: 'Ավելացնել',
          cancel: 'Չեղյալ',
          send_mail: 'Ուղղարկել նամակ'
        }
      },
      settings: {
        default_thumbnail: 'Սկզբնական նկար',
        change_image: 'Փոխել սկզբնական նկարը',
        hot_offer: 'Թեժ առաջարկ',
        save: 'Պահպանել',
        watermark: 'Watermark',
        company_id: 'Ընկերության id',
        home_page_parent_id: 'Կայքի գլխավոր էջ',
        titles: 'Էջեր',
        currencies: 'Արժույթներ'
      },
      user_requirements: {
        fname_lname: 'Անուն Ազգանուն',
        tel: 'Բջջ․ հեռ․',
        mail: 'Էլ․ հասցե',
        announcement_type: 'Գործարքի տեսակ',
        state: 'Մարզ',
        city: 'Համայնք',
        street: 'Փողոց',
        more: 'Ավելին',
        status: 'Կարգավիճակ',
        agent: 'Գործակալ',
        source: 'Աղբյուր',
        detached_house: {
          rooms: 'Սենյակներ',
          floor: 'Հարկայնություն',
          price: 'Գին',
          area: 'Մակերես',
          type_of_repair: 'Վերանորոգման տեսակ',
          exterior_walls: 'Արտաքին պատեր',
          m2_price: '1քմ արժեք',
        },
        apartment: {
          rooms: 'Սենյակներ',
          price: 'Գին',
          area: 'Մակերես',
          exterior_walls: 'Արտաքին պատեր',
          type_of_repair: 'Վերանորոգման տեսակ',
          m2_price: '1քմ արժեք',
          floor: 'Հարկ'
        },
        commercial: {
          commercial_use_type: 'Կոմերցիոն օգտ․ տեսակ',
          area: 'Մակերես',
          price: 'Գին',
          type_of_repair: 'Վերանորոգման տեսակ',
          exterior_walls: 'Արտաքին պատեր',
          m2_price: '1քմ արժեք',
        },
        land: {
          land_use: 'Հողի օգտագործման նպատակը',
          area: 'Մակերես',
          price: 'Գին',
          m2_price: '1քմ արժեք'
        }
      },
      pageTitle: {
        new_announcement: 'Նոր հայտարարություն',
        edit_announcement: 'Խմբագրել հայտարարությունը',
        classifiers: 'Դասակարգիչներ',
        posts: 'Բլոգ',
        new_post: 'Նոր փոստը',
        edit_post: 'Խմբագրել փոստը',
        home: 'Գլխավոր',
        users: 'Հաճախորդներ'
      },
      logIn: {
        username: 'Օգտանուն',
        password: 'Գաղտնաբառ',
        login: 'Մուտք'
      }
    },
    // Russian
    ru: {
      new_announcement: {
        global: {
          save: 'Сохранить',
          save_and_close: 'Сохранить и закрыть',
          cancel: 'Отменить',
          add_image: 'Добавить фотографии',
          delete_image: 'Удалить выбранные картинки',
          select_all: 'Выбрать все картинки',
          main: 'Основной',
          professional: 'Профессиональный',
          additional: 'Дополнительный'
        },
        main: {
          building_apartment: 'Здание/Квартира',
          utilities: 'Коммунальное хозяйство',
          map: 'Карта',
          land: 'Участок'
        },
        professional: {
          notes: 'Примечания',
          prof_note: 'Профессиональное мнение, аналитика',
          why_note: 'Почему бы мне не купить эту недвижимость?',
          other_note: 'Другие заметки',
          description: 'Описание',
          other_buildings: {
            title: 'Другие структуры',
            type: 'Тип',
            area: 'Область',
            description: 'Комментарий',
            add_button: 'Добавлять'
          }
        },
        additional: {
          title: 'Специалисты',
          seo_header: 'Заголовок SEO',
          seo_description: 'Описание SEO'
        },
        typeOfContract: 'Тип контракта',
        agent: 'Агент',
        // agent_options: this.announcement.getAgents('ru'),
        salesman: 'Продавец',
        state: 'Область',
        city: 'Город',
        street: 'Улица',
        1: {
          building: 'Дом',
          apartment: 'Квартира',
          floor: 'Этаж',
          building_floor: 'Этаж здания',
          rooms: 'Комнаты',
          ceiling_height: 'Высота потолка',
          total_area: 'Общая площадь',
          price: 'Цена'
        },
        2: {
          building: 'Дом',
          certificate: 'Сертификат',
          rooms: 'Комнаты',
          land_area: 'Площадь участка',
          building_area: 'Площадь застройки',
          price: 'Цена'
        },
        3: {
          building: 'Дом',
          separate_building: 'Отдельное здание',
          for_area: 'Для области',
          halls: 'Залы',
          total_area: 'Общая площадь',
          price: 'Цена'
        },
        4: {
          land: 'Земля',
          certificate: 'Сертификат',
          total_area: 'Общая площадь',
          price: 'Цена'
        }
      },
      classifiers: {
        property_type: 'Тип недвижимости',
        other_buildings_title: 'Другие структуры',
        new: 'Новый',
        all_classifiers: 'Все классификаторы',
        name: 'Имя',
        add: 'Добавить',
        select_one: 'Выберите хотя бы один',
        edit: 'Редактировать',
        close: 'Закрыть',
        save: 'Сохранить',
        delete: 'Удалить',
        add_close: 'Добавить и закрыть'
      },
      users: {
        clear: 'Стереть',
        search: 'Искать',
        new: 'Новъй',
        send_mail: 'Отправить письмо',
        download_exe: 'Скачать exe',
        results: 'Общее количество',
        code: 'Код',
        user_type: 'Тип контакта',
        name: 'Имя',
        phone: 'Тель.',
        viber: 'Viber',
        whatsapp: 'WhatsApp',
        contact_code: 'Контактный код',
        mail: 'Почта',
        urgent: 'Немедленно',
        createdAt: 'Созданный',
        updatedAt: 'Обновленный',
        to: 'До',
        new_user: {
          upload_photo: 'Загрузить картинку',
          name: 'Имя',
          surname: 'Фамилия',
          mail: 'Почта',
          tel: 'Сот. Тель.',
          password: 'Пароль',
          password_confirm: 'Подтверждение пароля',
          office_tel: 'Офиснъй тел.',
          home_tel: 'Домашний тел.',
          viber: 'Viber',
          whatsapp: 'WhatsApp',
          skype: 'Skype',
          description: 'Описание'
        },
        mail_component: {
          users_email_address: 'Электронные почты ползователей',
          subject: 'Тема',
          additional_documents: 'Дополнительные документы',
          add: 'Добавить',
          cancel: 'Отмена',
          send_mail: 'Отправить письмо'
        }
      },
      places: {
        all_places: 'Все места'
      },
      settings: {
        default_thumbnail: 'Начальное изображение',
        change_image: 'Изменить начальное изображение',
        hot_offer: 'Горячее предложение',
        save: 'Сохранить',
        watermark: 'Watermark',
        company_id: 'Идентификатор компании',
        home_page_parent_id: 'Главная страница сайта',
        titles: 'Страницъ',
        currencies: 'Валути'
      },
      user_requirements: {
        fname_lname: 'Имя Фамилия',
        tel: 'Сот. тел.',
        mail: 'Почта',
        announcement_type: 'Тип транзакции',
        state: 'Область',
        city: 'Сообщество',
        more: 'Больше',
        street: 'Улица',
        status: 'Категория',
        agent: 'Агент',
        source: 'Источник',
        detached_house: {
          rooms: 'Комнатъ',
          floor: 'Этаж',
          price: 'Цена',
          area: 'Площадь',
          type_of_repair: 'Тип ремонта',
          exterior_walls: 'Наружные стены',
          m2_price: 'Стоимость 1 кв.м․',
        },
        apartment: {
          rooms: 'Комнаты',
          price: 'Цена',
          area: 'Площадь',
          exterior_walls: 'Наружные стены',
          type_of_repair: 'Тип ремонта',
          m2_price: 'Стоимость 1 кв.м․',
          floor: 'Этаж'
        },
        commercial: {
          commercial_use_type: 'Тип коммерческого использования',
          area: 'Площадь',
          price: 'Цена',
          type_of_repair: 'Тип ремонта',
          exterior_walls: 'Наружные стены',
          m2_price: 'Стоимость 1 кв.м․',
        },
        land: {
          land_use: 'Цель использования земли',
          area: 'Площадь',
          price: 'Цена',
          m2_price: 'Стоимость 1 кв.м․',
        }
      },
      pageTitle: {
        new_announcement: 'Новое объявление',
        edit_announcement: 'Редактировать объявление',
        classifiers: 'Классификаторы',
        posts: 'Блог',
        new_post: 'Новый пост',
        edit_post: 'Редактировать пост',
        home: 'Главная',
        users: 'Пользователи'
      },
      logIn: {
        username: 'Логин',
        password: 'Пароль',
        login: 'Войти'
      }
    },
    // English
    en: {
      new_announcement: {
        global: {
          save: 'Save',
          save_and_close: 'Save and close',
          cancel: 'Cancel',
          add_image: 'Add pictures',
          delete_image: 'Delete selected pictures',
          select_all: 'Select all pictures',
          main: 'Main',
          professional: 'Professional',
          additional: 'Additional'
        },
        main: {
          building_apartment: 'Building/Apartment',
          utilities: 'Utilities',
          map: 'Map',
          land: 'Land'
        },
        professional: {
          notes: 'Notes',
          prof_note: 'Professional Opinion, Analytics',
          why_note: 'Why would I not buy this property?',
          other_note: 'Other notes',
          description: 'Description',
          other_buildings: {
            title: 'Other buildings',
            type: 'Type',
            area: 'Area',
            description: 'Description',
            add_button: 'Add'
          }
        },
        additional: {
          title: 'Specialists',
          seo_header: 'Header SEO',
          seo_description: 'Description SEO'
        },
        typeOfContract: 'Type of contract',
        agent: 'Agent',
        // agent_options: this.announcement.getAgents('en'),
        salesman: 'Salesman',
        state: 'State',
        city: 'City',
        street: 'Street',
        1: {
          building: 'Building',
          apartment: 'Apartment',
          floor: 'Floor',
          building_floor: 'Building floor',
          rooms: 'Rooms',
          ceiling_height: 'Ceiling height',
          total_area: 'Total area',
          price: 'Price'
        },
        2: {
          building: 'Building',
          certificate: 'Certificate',
          rooms: 'Rooms',
          land_area: 'Plot area',
          building_area: 'Building area',
          price: 'Price'
        },
        3: {
          building: 'Building',
          separate_building: 'Separate building',
          for_area: 'For area',
          halls: 'Halls',
          total_area: 'Total area',
          price: 'Price'
        },
        4: {
          land: 'Land',
          certificate: 'Certificate',
          total_area: 'Total area',
          price: 'Price'
        }
      },
      classifiers: {
        property_type: 'Property type',
        other_buildings_title: 'Other buildings',
        new: 'New',
        all_classifiers: 'All classifiers',
        name: 'Name',
        add: 'Add',
        select_one: 'Select at least one',
        edit: 'Edit',
        close: 'Close',
        save: 'Save',
        delete: 'Delete',
        add_close: 'Add and close'
      },
      users: {
        clear: 'Clear',
        search: 'Search',
        new: 'New',
        send_mail: 'Send mail',
        download_exe: 'Donwload exe',
        results: 'Total amount',
        code: 'Code',
        user_type: 'Contact type',
        name: 'Name',
        phone: 'Tel.',
        viber: 'Viber',
        whatsapp: 'WhatsApp',
        contact_code: 'Contact code',
        mail: 'Email',
        urgent: 'Urgently',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        to: 'To',
        new_user: {
          upload_photo: 'Upload photo',
          name: 'First name',
          surname: 'Last name',
          mail: 'Email',
          tel: 'Cell. Tel.',
          password: 'password',
          password_confirm: 'password confirm',
          office_tel: 'Office tel.',
          home_tel: 'Home tel.',
          viber: 'Viber',
          whatsapp: 'WhatsApp',
          skype: 'Skype',
          description: 'Description'
        },
        mail_component: {
          users_email_address: 'Users email address',
          subject: 'Subject',
          additional_documents: 'Additional documents',
          add: 'Add',
          cancel: 'Cancel',
          send_mail: 'Send mail'
        }
      },
      places: {
        all_places: 'All places'
      },
      settings: {
        default_thumbnail: 'Default thumbnail',
        change_image: 'Change default thumbnail',
        hot_offer: 'Hot offer',
        save: 'Save',
        watermark: 'Watermark',
        company_id: 'Company id',
        home_page_parent_id: 'Home page of website',
        titles: 'Pages',
        currencies: 'Currencies'
      },
      user_requirements: {
        fname_lname: 'Name Surname',
        tel: 'Phone tel.',
        mail: 'Email',
        announcement_type: 'Type of transaction',
        state: 'State',
        city: 'City',
        more: 'More',
        street: 'Street',
        status: 'Status',
        agent: 'Agent',
        source: 'Source',
        detached_house: {
          rooms: 'Rooms',
          floor: 'Floor',
          price: 'Price',
          area: 'Area',
          type_of_repair: 'Type of repair',
          exterior_walls: 'Exterior walls',
          m2_price: '1 sq.m price',
        },
        apartment: {
          rooms: 'Rooms',
          price: 'Price',
          area: 'Area',
          exterior_walls: 'Exterior walls',
          type_of_repair: 'Type of repair',
          m2_price: '1 sq.m price',
          floor: 'Floor'
        },
        commercial: {
          commercial_use_type: 'Type of commercial use',
          area: 'Area',
          price: 'Price',
          type_of_repair: 'Type of repair',
          exterior_walls: 'Exterior walls',
          m2_price: '1 sq.m price',
        },
        land: {
          land_use: 'Purpose of land use',
          area: 'Area',
          price: 'Price',
          m2_price: '1 sq.m price'
        }
      },
      pageTitle: {
        new_announcement: 'New announcement',
        edit_announcement: 'Edit announcement',
        classifiers: 'Classifiers',
        posts: 'Blog',
        new_post: 'New post',
        edit_post: 'Edit post',
        home: 'Home',
        users: 'Users'
      },
      logIn: {
        username: 'Email',
        password: 'Password',
        login: 'Log-in'
      }
    }
  };

  constructor() { }
}
