import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { User } from '../interfaces/users/users';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /* tslint:disable */

  authorized_token: any;
  current_user: any;

  constructor(private http: HttpClient,
              public jwtHelper: JwtHelperService) {  }
              
  headers() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${window.localStorage.getItem('token')}`
    });

    return headers;
  }

  postSignIn(obj) {
    return this.http.post<{access_token: any, token_type: any, expires_at: any}>(`${API_URL}/auth/login`, obj);
  }

  isAuthenticated(): boolean {
    const token = window.localStorage.getItem('token');
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  getCurrentUser(): Observable<any> {
    const headers = this.headers();
    return this.http.get(`${API_URL}/auth/user`, {headers});
  }
}
